export class Infrastructure {
  getMeetUrl(roomId: string) {
    return import("./get-meet-url").then(module => module.default(roomId));
  }

  getConfig() {
    return import("./config").then(module => module.default());
  }

  authByCode(code: string) {
    return import("./auth-by-code").then(module => module.default(code));
  }

  createMeet(roomID: string) {
    return import("./create-meet").then(module => module.default(roomID));
  }

  refresh() {
    return import("./refresh").then(module => module.default());
  }
}
